import React, { useState, useEffect } from "react";
import { Button } from "../../../components/Button";
import HeaderContent from "../../../layouts/HeaderContent";
import { Input } from "../../../components/Input";
import { FiSave, FiXCircle } from "react-icons/fi";
import axios from "../../../utils/axios";
import { useMutation, QueryClient } from "react-query";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { toastSuccess, toastError } from "../../../utils/toast";
import { IoAddCircle, IoAddCircleOutline } from "react-icons/io5";
import { BiTrash } from "react-icons/bi";
import { baseUrl, rupiah } from "../../../utils/strings";

const EditBahan = () => {
  const location = useLocation();
  const dataProduk = location.state;
  const user = JSON.parse(localStorage.getItem("user"));

  const [errMessage, setErrMessage] = useState("");
  const [isAction, setIsAction] = useState(false);
  const [isLoadingBahan, setIsLoadingBahan] = useState(true);
  const queryClient = new QueryClient();
  const navigate = useNavigate();
  const breadcrumbs = [
    { link: "/", menu: "Home" },
    { link: "/produk", menu: "Produk" },
    { link: "/produk/edit", menu: "Edit Kuantiti Produk" },
  ];
  const [bahanValues, setBahanValues] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [availableBahan, setAvailableBahan] = useState([]);
  const [isFirstAdd, setIsFirstAdd] = useState(true); 

  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
  } = useForm();

  useEffect(() => {
    fetchBahanProduk();
  }, []);

  useEffect(() => {
    fetchAvailableBahan();
  }, []);

  const fetchBahanProduk = async () => {
    try {
      axios.defaults.headers.common["Authorization"] = `Bearer ${user.token}`;
      const response = await axios.get(`produk/${dataProduk.id}/bahan`);
      if (response.data && response.data.bahan) {
        const data = response.data.bahan.map((item) => ({
          nama_bahan: item.nama_bahan,
          qty: item.qty,
          stok: item.stok,
          id: item.id_bahan,
        }));
        // setBahanValues(data);
      } else {
        console.error('Data tidak ada');
      }
    } catch (error) {
      console.error('Error fetching bahan produk:', error);
    } finally {
      setIsLoadingBahan(false);
    }
  };

  const submitData = async (data) => {
    data.bahan = bahanValues.map((item) => ({
      'id_bahan': item.id,
      'qty': item.qty,
    }));

    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const response = await axios.put("produk/ubah-bahan/" + dataProduk.id, data, config);
    const res = response.data;

    if (response.status !== 200) {
      throw new Error(res.meta.message);
    }

    return res.data;
  };

  const mutation = useMutation(submitData, {
    onSettled: async (data, error) => {
      setIsAction(false);
      if (data) {
        queryClient.invalidateQueries("data-produk");
        reset();
        setErrMessage("");
        clearErrors();
      }
      if (error) {
        setErrMessage(error.message);
      }
    },
    onSuccess: async () => {
      navigate("/produk");
      toastSuccess("Produk Berhasil Diedit");
    },
    onError: async () => {
      toastError("Produk Gagal Diedit");
    },
  });

  const editDataStok = async (data) => {
    await mutation.mutate(data);
  };

  const fetchAvailableBahan = async () => {
    try {
      axios.defaults.headers.common["Authorization"] = `Bearer ${user.token}`;
      const idResto = dataProduk.id_resto;
      const response = await axios.get(`bahan/all?id_resto=${idResto}`);
      setAvailableBahan(response.data.data || []);
    } catch (error) {
      console.error("Error fetching available bahan:", error);
      setAvailableBahan([]);
    } finally {
      setIsLoadingBahan(false);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredBahan = availableBahan.filter((bahan) =>
    bahan.nama_bahan.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleAddBahan = (bahan) => {
    if (isFirstAdd) {
      setBahanValues([]);
      setIsFirstAdd(false);
    }

    setBahanValues((prevValues) => {
      const existingBahan = prevValues.find((item) => item.id === bahan.id);
      if (existingBahan) {
        return prevValues.map((item) =>
          item.id === bahan.id ? { ...item, qty: item.qty + 1 } : item
        );
      } else {
        return [...prevValues, { ...bahan, qty: 1 }];
      }
    });
  };

  const handleQtyChange = (id, value) => {
    setBahanValues((prevValues) =>
      prevValues.map((item) =>
        item.id === id ? { ...item, qty: value } : item
      )
    );
  };

  const handleRemoveBahan = (id) => {
    setBahanValues((prevValues) => prevValues.filter((item) => item.id !== id));
  };

  return (
    <>
      <HeaderContent
        linkBack="/produk"
        title="Edit Kuantiti Produk"
        breadcrumbs={breadcrumbs}
      />
      <div className="bg-white h-max px-6 rounded-lg mt-0 md:mt-4">
        <form onSubmit={handleSubmit(editDataStok)} className="grid grid-cols-3 gap-3">
          <div className="col-span-1">
            <input type="hidden" name="id" {...register("id")} />
            <div className="relative h-fit bg-white shadow-md rounded-2xl p-2 mx-1">
              <div className="rounded-2xl">
                <img className="h-40 rounded-2xl w-full" src={baseUrl + dataProduk.gambar} alt={dataProduk.gambar} />
              </div>
              <div className="mt-4 pl-2 mb-2 flex justify-between">
                <div>
                  <p className="text-lg font-semibold text-gray-900 mb-0">{dataProduk.nama_produk}</p>
                  <p className="text-md text-gray-800 mt-0">SKU : {dataProduk.nomor_sku}</p>
                  <p className="text-md text-gray-800 mt-0">Kategori : {dataProduk.kategori_produk.kategori_produk}</p>
                  <p className="text-md text-gray-800 mt-0">Harga Jual : Rp. {rupiah(dataProduk.harga_jual)}</p>
                </div>
              </div>
            </div>
            <div className="col-span-3 flex justify-end items-center mt-4">
              <Button
                className="text-xs mr-2 bg-custom-blue border-custom-blue"
                type="submit"
                startIcon={<FiSave size={20} />}
                loading={isAction}
                title="Simpan"
              />
              <Button
                className="text-xs"
                color="ghost"
                type="button"
                startIcon={<FiXCircle size={20} />}
                title="Kembali"
                onClick={() => navigate("/produk")}
              />
            </div>
          </div>
          <div className="col-span-2">
            <h3 className="text-lg font-medium mb-2">Bahan yang Dipilih</h3>
            <table className="min-w-full divide-y divide-gray-200 mb-5">
              <thead>
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nama Bahan</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Jumlah Bahan</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Aksi</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {bahanValues.map((item) => (
                  <tr key={item.id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.nama_bahan}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <input
                        type="number"
                        value={item.qty}
                        onChange={(e) => handleQtyChange(item.id, e.target.value)}
                        className="border rounded-md w-full p-1"
                        min="1"
                      />
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <Button
                        color="ghost"
                        type="button"
                        startIcon={<BiTrash size={20} />}
                        onClick={() => handleRemoveBahan(item.id)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <h3 className="text-lg font-medium mb-2">Tambah Bahan</h3>
            <div className="flex items-center mb-2">
              <Input
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="Cari bahan..."
                className="mr-2"
              />
              <Button
                type="button"
                className="ml-2 bg-custom-blue"
                title="Clear"
                onClick={() => setSearchTerm("")}
              />
            </div>
            <div className="max-h-60 overflow-y-auto border border-gray-300 rounded-md mb-4">
              <ul className="divide-y divide-gray-200">
                {filteredBahan.map((bahan) => (
                  <li key={bahan.id} className="flex justify-between items-center px-4 py-2">
                    <span>{bahan.nama_bahan}</span>
                    <Button
                      type="button"
                      className="text-xs bg-custom-blue"
                      color="white"
                      onClick={() => handleAddBahan(bahan)}
                      startIcon={<IoAddCircleOutline size={20} />}
                    >
                      Tambah
                    </Button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditBahan;
