import React, { useState, useEffect } from 'react';

// components
import { Button } from '../../../components/Button'
import HeaderContent from '../../../layouts/HeaderContent'
import { InputGroup, Input, MessageError } from '../../../components/Input'

// icons
import { FiSave, FiXCircle } from 'react-icons/fi'

// libraries
import axios from '../../../utils/axios'
import { swNormal } from '../../../utils/sw'
import { useMutation, QueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { useForm } from "react-hook-form";
import { toastSuccess, toastError } from '../../../utils/toast'

const Tambah = () => {
	const navigate = useNavigate()
	const user = JSON.parse(localStorage.getItem('user'))
	const queryClient = new QueryClient()
	const [isAction, setIsAction] = useState(false)
	const breadcrumbs = [
		{ link: '/', menu: 'Home' },
		{ link: '/bahan', menu: 'Bahan' },
		{ link: '/bahan/tambah', menu: 'Tambah Bahan' },
	]

	const { register, handleSubmit, formState: { errors }, reset, clearErrors, control, setFocus } = useForm();
	
	const mutation = useMutation(async (data) => {
		const config = {     
		    headers: {
		    	Authorization: `Bearer ${user.token}`
		    }
		}

		const response = await axios.post('bahan', data, config)
		const res = response.data

		if(res.meta.code != 200) {
			throw new Error(res.meta.message)
		}

		return res.data
	}, {
		onMutate: () => {
			// spinner
			setIsAction(true)
		},
		onSettle: async (data, error) => {
			setIsAction(false)

			if(data) {
				queryClient.invalidateQueries('data-bahan')
				reset()
				clearErrors()
			}
		},
		onSuccess: async () => {
			navigate('/bahan')
			toastSuccess('Bahan Berhasil Ditambahkan')
		},
		onError: async () => {
			navigate('/bahan')
			toastError('Bahan Gagal Ditambahkan')
		}
	})

	const addData = async (data) => {
		await mutation.mutate(data)
	}

	useEffect(() => {
		setFocus('nama_bahan')
	}, [setFocus])

    return (
        <React.Fragment>
        	<HeaderContent linkBack="/bahan" title="Tambah Bahan" breadcrumbs={breadcrumbs} />
		    <div className="bg-white h-max px-6 rounded-lg mt-0 md:mt-4">
		    	<form onSubmit={handleSubmit(addData)}>

			      	<div className="grid grid-cols-8 gap-1 md:gap-4 mb-4">
			      		<label className="label col-span-12 md:col-span-2">
				          <span className="label-text">Bahan</span>
				        </label>
			      		<div className="relative col-span-12 md:col-span-4">
			      			<Input type="text" placeholder="Bahan" name="nama_bahan" control={control} required error={errors.nama_bahan ? true : false}/>
							{errors.nama_bahan && <MessageError>Bahan Tidak Boleh Kosong</MessageError>}
			      		</div>
					</div>
					
					<div className="grid grid-cols-8 md:gap-4 mt-8 mb-8">
			      		<div className="relative col-span-12 gap-x-2 md:col-span-4 md:col-start-3">
			      			<Button className="text-xs mr-2 bg-custom-blue border-custom-blue" type="submit" startIcon={<FiSave size={20}/>} loading={isAction} title="Simpan" />
					        <Button className="text-xs" color="ghost" type="button" startIcon={<FiXCircle size={20}/>} loading={false} title="Kembali" onClick={() => navigate('/bahan')}/>
			      		</div>
					</div>

		    	</form>

		    </div>
  
        </React.Fragment>
    );
};

export default Tambah;
