import React, { useState, useEffect } from 'react';

// components
import { Button } from '../../../components/Button';
import HeaderContent from '../../../layouts/HeaderContent';
import { Input, MessageError } from '../../../components/Input'; // Pastikan Input diimpor dengan benar

// icons
import { FiSave, FiXCircle } from 'react-icons/fi';

// libraries
import axios from '../../../utils/axios';
import { useMutation, QueryClient, useQuery } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { toastSuccess, toastError } from '../../../utils/toast';
import Select from 'react-select';

const Edit = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const user = JSON.parse(localStorage.getItem('user'));
	const queryClient = new QueryClient();
	const [isAction, setIsAction] = useState(false);
	const [selectedRestos, setSelectedRestos] = useState([]);
	const breadcrumbs = [
		{ link: '/', menu: 'Home' },
		{ link: '/group-outlet', menu: 'Group Outlet' },
		{ link: '/group-outlet/edit', menu: 'Edit Group Outlet' },
	];

	const { register, handleSubmit, formState: { errors }, reset, clearErrors, setFocus, setValue } = useForm();

	const { data: restos, isLoading: isLoadingRestos } = useQuery('resto-list', async () => {
		const config = {
			headers: {
				Authorization: `Bearer ${user.token}`,
			},
		};
		const response = await axios.get('resto?s=&limit=200&sort=DESC&page=1', config);
		return response.data.data.data;
	}, {
		refetchOnWindowFocus: false,
	});

	const fetchGroupOutletDetail = async (id) => {
		const config = {
			headers: {
				Authorization: `Bearer ${user.token}`,
			},
		};
		const response = await axios.get(`group-outlet/${id}`, config);
		return response.data;
	};

	const mutation = useMutation(async (data) => {
		const config = {
			headers: {
				Authorization: `Bearer ${user.token}`,
			},
		};
		const response = await axios.put(`group-outlet/${data.id}`, {
			nama_outlet: data.nama_outlet,
			id_resto: data.id_resto
		}, config);

		const res = response.data;

		if (response.status !== 200) {
			throw new Error(res.meta.message);
		}

		return res.data;
	}, {
		onMutate: () => {
			setIsAction(true);
		},
		onSettled: (data) => {
			setIsAction(false);
			if (data) {
				queryClient.invalidateQueries('data-group-outlet');
				reset();
				clearErrors();
			}
		},
		onSuccess: () => {
			navigate('/group-outlet');
			toastSuccess('Group Outlet Berhasil Diedit');
		},
		onError: () => {
			toastError('Group Outlet Gagal Diedit');
		},
	});

	const editData = async (data) => {
		await mutation.mutate(data);
	};	

	useEffect(() => {
		const groupOutletId = location.state?.id;
		if (groupOutletId) {
			fetchGroupOutletDetail(groupOutletId).then((groupData) => {
				if (groupData && groupData.group_outlet) {
					setValue('id', groupData.group_outlet.id);
					setValue('nama_outlet', groupData.group_outlet.nama_outlet);

					const selectedOptions = groupData.restos.map(resto => ({
						value: resto.id,
						label: resto.nama_resto,
					}));
					setSelectedRestos(selectedOptions);
					setValue('id_resto', selectedOptions.map(option => option.value));
				}
			});
		}
		setFocus('nama_outlet');
	}, [location.state, setFocus, setValue]);


	const handleSelectResto = (selectedOptions) => {
		const selectedIds = selectedOptions.map(option => option.value);
		setSelectedRestos(selectedOptions);
		setValue('id_resto', selectedIds);
	};

	return (
		<React.Fragment>
			<HeaderContent linkBack="/group-outlet" title="Edit Group Outlet" breadcrumbs={breadcrumbs} />
			<div className="bg-white h-max px-6 rounded-lg mt-0 md:mt-4">
				<form onSubmit={handleSubmit(editData)}>
					<input type="hidden" name="id" {...register("id")} />

					<div className="grid grid-cols-8 gap-1 md:gap-4 mb-4">
						<label className="label col-span-12 md:col-span-2">
							<span className="label-text">Nama Outlet</span>
						</label>
						<div className="relative col-span-12 md:col-span-4">
							<Input
								type="text"
								placeholder="Nama Outlet"
								name="nama_outlet"
								{...register("nama_outlet", { required: true })}
								onChange={(e) => setValue('nama_outlet', e.target.value)} // Sinkronisasi manual jika diperlukan
							/>
							{errors.nama_outlet && <MessageError>Nama Outlet Tidak Boleh Kosong</MessageError>}
						</div>
					</div>

					<div className="grid grid-cols-8 gap-1 md:gap-4 mb-4">
						<label className="label col-span-12 md:col-span-2">
							<span className="label-text">Resto</span>
						</label>
						<div className="relative col-span-12 md:col-span-4">
							{isLoadingRestos ? (
								<p>Loading Restos...</p>
							) : (
								<Select
									isMulti
									options={restos?.map(resto => ({
										value: resto.id,
										label: resto.nama_resto,
									}))}
									value={selectedRestos}
									onChange={handleSelectResto}
									placeholder="Pilih Resto"
								/>
							)}
							{errors.id_resto && <MessageError>Resto Tidak Boleh Kosong</MessageError>}
						</div>
					</div>

					<div className="grid grid-cols-8 md:gap-4 mt-8 mb-8">
						<div className="relative col-span-12 gap-x-2 md:col-span-4 md:col-start-3">
							<Button className="text-xs mr-2 bg-custom-blue border-custom-blue" type="submit" startIcon={<FiSave size={20} />} loading={isAction} title="Simpan" />
							<Button className="text-xs" color="ghost" type="button" startIcon={<FiXCircle size={20} />} loading={false} title="Kembali" onClick={() => navigate('/group-outlet')} />
						</div>
					</div>
				</form>
			</div>
		</React.Fragment>
	);
};

export default Edit;
