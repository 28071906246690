import React, { useState, useEffect, useRef } from 'react'

// components
import { Button, ButtonIconOutline } from '../../../components/Button'
import { InputGroup } from '../../../components/Input'
import Pagination from '../../../components/Pagination'
import LoadingTable from '../../../components/LoadingTable'
import HeaderContent from '../../../layouts/HeaderContent'
import TableContent from '../../../layouts/TableContent'
import PaginationTable from '../../../components/PaginationTable'

// icons
import { RiArrowLeftSFill, RiArrowRightSFill } from 'react-icons/ri'
import { FiTrash2, FiEdit3, FiToggleRight, FiPlusCircle, FiSearch, FiEye, FiUserX } from 'react-icons/fi'

// libraries
import { Link } from 'react-router-dom'
import { useQuery } from 'react-query'
import axios from '../../../utils/axios'
import { swNormal, swConfirm } from '../../../utils/sw'
import { useMutation, QueryClient, useQueryClient } from 'react-query'
import { useForm } from "react-hook-form";
import { toastSuccess, toastError } from '../../../utils/toast'

const GroupOutlet = () => {
    const user = JSON.parse(localStorage.getItem('user'))
    const [isShowModal, setIsShowModal] = useState(false)
    const breadcrumbs = [
        { link: '/', menu: 'Home' },
        { link: '/group-outlet', menu: 'Group Outlet' },
    ]

    const [showDropdownAksi, setShowDropdownAksi] = useState({ id: 0, status: false })
    const elementDropdownAksi = useRef()
    const btnDropdownAksi = useRef(null)

    // pagination
    const [page, setPage] = useState(1)
    const [nextPageUrl, setNextPageUrl] = useState(null)
    const [prevPageUrl, setPrevPageUrl] = useState(null)
    const [toRow, setToRow] = useState(1)
    const [fromRow, setFromRow] = useState(1)
    const [limit, setLimit] = useState(10)
    const [totalRows, setTotalRows] = useState(0)
    const [keyword, setKeyword] = useState('')

    // react query
    const queryClient = new QueryClient()
    const {
        isLoading,
        isError,
        error,
        data,
        isSuccess,
        isFetching,
        refetch,
        isPreviousData,
    } = useQuery(["data-group-outlet", page], () => fetchData(), {
        staleTime: 0,
        refetchInterval: 0,
        keepPreviousData: true,
        refetchOnWindowFocus: false
    })

    const mutation = useMutation(async (id) => {
        axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`
        const response = await axios.delete('group-outlet/' + id)
        const res = response.data

        if (response.status !== 200) {
            throw new Error(res.meta.message)
        }

        return response.status
    }, {
        onSettled: async (data, error) => {
            if (data === 200) {
                refetch()
            }
        },
        onSuccess: async () => {
            toastSuccess('Group Outlet Berhasil Dihapus')
        },
        onError: async () => {
            toastError('Group Outlet Gagal Dihapus')
        }
    })

    // Fungsi untuk menghapus id_multi_outlet
    const mutationRemoveMultiOutlet = useMutation(async (groupOutletId) => {
        axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`
        const response = await axios.delete(`group-outlet/${groupOutletId}/remove-multi-outlet`)
        const res = response.data

        if (response.status !== 200) {
            throw new Error(res.meta.message)
        }

        return response.status
    }, {
        onSettled: async (data, error) => {
            if (data === 200) {
                refetch()
            }
        },
        onSuccess: async () => {
            toastSuccess('Multi Outlet Berhasil Dihapus')
        },
        onError: async () => {
            toastError('Multi Outlet Gagal Dihapus')
        }
    })

    // fetch data
    const fetchData = async () => {
        try {
            axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
            const response = await axios.get(`group-outlet?s=${keyword}&limit=${limit}&sort=DESC&page=${page}`);
            const res = response.data;

            if (!res) {
                throw new Error('Respon tidak valid');
            }

            const data = res || [];
            setTotalRows(res.total || 0);
            setFromRow(res.from || 0);
            setToRow(res.to || 0);
            setNextPageUrl(res.next_page_url || null);
            setPrevPageUrl(res.prev_page_url || null);

            return data;
        } catch (error) {
            console.error("Error fetching data:", error);
            throw error;
        }
    }

    // refetch after searching
    useEffect(() => {
        refetch()
    }, [keyword, limit])

    // pagination action
    const nextPage = () => nextPageUrl != null ? setPage(page + 1) : null
    const prevPage = () => prevPageUrl != null ? setPage(page - 1) : null

    const confirmDeleteData = (id) => {
        const confirm = swConfirm()
        confirm.then(result => {
            if (result.isConfirmed) {
                mutation.mutate(id)
            }
        })
    }

    const confirmRemoveMultiOutlet = (groupOutletId) => {
        const confirm = swConfirm()
        confirm.then(result => {
            if (result.isConfirmed) {
                mutationRemoveMultiOutlet.mutate(groupOutletId)
            }
        })
    }

    return (
        <>
            <HeaderContent title="Group Outlet" breadcrumbs={breadcrumbs}>
                <div className="md:flex mt-4 md:mt-0 flex-1 md:space-x-3 block space-y-3 md:space-y-0">
                    <div className="relative flex-1 w-full">
                        <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                            <FiSearch size={20} />
                        </div>
                        <input type="text" placeholder="Cari Group Outlet" onChange={(e) => setKeyword(e.target.value)} className="input input-bordered w-full pl-10 p-2.5 col-span-2" />
                    </div>
                    <div className="text-right">
                        <Link to="/group-outlet/tambah">
                            <Button className="text-xs bg-custom-blue border-custom-blue" type="button" startIcon={<FiPlusCircle size={20} />} loading={false} title="Tambah Group Outlet" />
                        </Link>
                    </div>
                </div>
            </HeaderContent>
            <div className="bg-white mt-4 px-6">
                <TableContent>
                    <thead className="text-xs text-blue-500 bg-blue-50 uppercase">
                        <tr className="border-b border-blue-200">
                            <th scope="col" className="py-3 px-6 rounded-tl-md">
                                No.
                            </th>
                            <th scope="col" className="py-3 px-6">
                                Group Outlet
                            </th>
                            <th scope="col" className="py-3 px-6">
                                User Multi Outlet
                            </th>
                            <th scope="col" className="py-3 px-6 rounded-tr-md">
                                Aksi
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {isFetching ? (
                            <LoadingTable colSpan="3" />
                        ) : isError ? (
                            <tr>
                                <td className="py-4 px-6 text-center font-medium w-max" colSpan="3">
                                    Gagal Mengambil Data
                                </td>
                            </tr>
                        ) : data && data.length > 0 ? (
                            data.map((obj, key) => (
                                <tr className="bg-white border-b" key={key}>
                                    <td className="py-4 px-6 whitespace-nowrap text-sm text-center">
                                        {++key}
                                    </td>
                                    <td className="py-4 px-6 text-center">
                                        {obj.nama_outlet}
                                    </td>
                                    <td className="py-4 px-6 text-center">
                                        {obj.multi_outlet_user?.name || '-'}
                                    </td>
                                    <td className="py-4 px-6 text-center">
                                        <div className="md:space-x-3 space-x-0">
                                            <div className="tooltip tooltip-bottom" data-tip="Lihat Detail Outlet">
                                                <Link to={`/group-outlet/detail/${obj.id}`} state={obj}>
                                                    <ButtonIconOutline>
                                                        <FiEye size="16" />
                                                    </ButtonIconOutline>
                                                </Link>
                                            </div>
                                            <div className="tooltip tooltip-bottom" data-tip="Edit Group Outlet">
                                                <Link to={`/group-outlet/edit/${obj.id}`} state={obj}>
                                                    <ButtonIconOutline>
                                                        <FiEdit3 size="16" />
                                                    </ButtonIconOutline>
                                                </Link>
                                            </div>
                                            <div className="tooltip tooltip-bottom" data-tip="Hapus Group Outlet">
                                                <ButtonIconOutline onClick={() => confirmDeleteData(obj.id)}>
                                                    <FiTrash2 size="16" />
                                                </ButtonIconOutline>
                                            </div>
                                            {/* Tampilkan tombol hapus jika id_multi_outlet ada */}
                                            {obj.id_multi_outlet && (
                                                <div className="tooltip tooltip-bottom" data-tip="Hapus Multi Outlet">
                                                    <ButtonIconOutline onClick={() => confirmRemoveMultiOutlet(obj.id)}>
                                                        <FiUserX size="16" />
                                                    </ButtonIconOutline>
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td className="py-4 px-6 text-center font-medium w-max" colSpan="3">
                                    Tidak Ada Data
                                </td>
                            </tr>
                        )}
                    </tbody>
                </TableContent>
                <PaginationTable>
                    <div className="flex-1">
                        Menampilkan {fromRow} - {toRow} dari {totalRows} Data
                    </div>
                    <div>
                        <button onClick={prevPage} disabled={isPreviousData || page === 1} className="btn btn-outline">
                            <RiArrowLeftSFill />
                        </button>
                        <span className="mx-2">{page}</span>
                        <button onClick={nextPage} disabled={isPreviousData || !nextPageUrl} className="btn btn-outline">
                            <RiArrowRightSFill />
                        </button>
                    </div>
                </PaginationTable>
            </div>
        </>
    )
}

export default GroupOutlet