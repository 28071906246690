import React, { useState, useEffect } from 'react';

// components
import { Button } from '../../../components/Button';
import HeaderContent from '../../../layouts/HeaderContent';
import { InputGroup, Select, Input, MessageError } from '../../../components/Input';

// icons
import { FiSave, FiXCircle } from 'react-icons/fi';

// libraries
import axios from '../../../utils/axios';
import { useMutation, QueryClient, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { toastSuccess, toastError } from '../../../utils/toast';

const Tambah = () => {
	const navigate = useNavigate();
	const user = JSON.parse(localStorage.getItem('user'));
	const queryClient = new QueryClient();
	const [isAction, setIsAction] = useState(false);
	const breadcrumbs = [
		{ link: '/', menu: 'Home' },
		{ link: '/group-outlet', menu: 'Group Outlet' },
		{ link: '/group-outlet/tambah', menu: 'Tambah Group Outlet' },
	];

	const { register, handleSubmit, formState: { errors }, reset, clearErrors, control, setFocus, setValue } = useForm();

	// Fetch data resto untuk select box
	const { data: restos, isLoading: isLoadingRestos } = useQuery('resto-list', async () => {
		const config = {
			headers: {
				Authorization: `Bearer ${user.token}`,
			},
		};
		const response = await axios.get('resto?s=&limit=200&sort=DESC&page=1', config);
		return response.data.data.data;
	}, {
		refetchOnWindowFocus: false,
	});

	const mutation = useMutation(async (data) => {
		const config = {
			headers: {
				Authorization: `Bearer ${user.token}`,
			},
		};

		// Endpoint untuk group outlet
		const response = await axios.post('group-outlet', {
			nama_outlet: data.nama_outlet, 
			id_resto: data.id_resto // Menambahkan array id_resto
		  }, config); 		  
		const res = response.data;

		if (response.status !== 201) {
			throw new Error(res.meta.message);
		}

		return res.data;
	}, {
		onMutate: () => {
			setIsAction(true);
		},
		onSettled: async (data, error) => {
			setIsAction(false);

			if (data) {
				queryClient.invalidateQueries('data-group-outlet');
				reset();
				clearErrors();
			}
		},
		onSuccess: async () => {
			navigate('/group-outlet');
			toastSuccess('Group Outlet Berhasil Ditambahkan');
		},
		onError: async () => {
			toastError('Group Outlet Gagal Ditambahkan');
		},
	});

	const addData = async (data) => {
		await mutation.mutate(data);
	};

	useEffect(() => {
		setFocus('nama_outlet'); // Set focus pada nama outlet saat komponen dimuat
	}, [setFocus]);

	// Fungsi untuk handle perubahan pilihan resto
	const handleSelectResto = (selectedOptions) => {
		const selectedIds = selectedOptions.map(option => option.value); // Mengambil ID resto yang dipilih
		setValue('id_resto', selectedIds); // Set id_resto sebagai array
	};

	return (
		<React.Fragment>
			<HeaderContent linkBack="/group-outlet" title="Tambah Group Outlet" breadcrumbs={breadcrumbs} />
			<div className="bg-white h-max px-6 rounded-lg mt-0 md:mt-4">
				<form onSubmit={handleSubmit(addData)}>

					<div className="grid grid-cols-8 gap-1 md:gap-4 mb-4">
						<label className="label col-span-12 md:col-span-2">
							<span className="label-text">Nama Outlet</span>
						</label>
						<div className="relative col-span-12 md:col-span-4">
							<Input
								type="text"
								placeholder="Nama Outlet"
								name="nama_outlet"
								control={control}
								required
								error={errors.nama_outlet ? true : false}
							/>
							{errors.nama_outlet && <MessageError>Nama Outlet Tidak Boleh Kosong</MessageError>}
						</div>
					</div>

					<div className="grid grid-cols-8 gap-1 md:gap-4 mb-4">
						<label className="label col-span-12 md:col-span-2">
							<span className="label-text">Resto</span>
						</label>
						<div className="relative col-span-12 md:col-span-4">
							{isLoadingRestos ? (
								<p>Loading Restos...</p>
							) : (
								<Select
									isMulti
									options={restos?.map(resto => ({
										value: resto.id,
										label: resto.nama_resto, // Mengambil nama resto
									}))}
									onChange={handleSelectResto}
									placeholder="Pilih Resto"
								/>
							)}
							{errors.id_resto && <MessageError>Resto Tidak Boleh Kosong</MessageError>}
						</div>
					</div>

					<div className="grid grid-cols-8 md:gap-4 mt-8 mb-8">
						<div className="relative col-span-12 gap-x-2 md:col-span-4 md:col-start-3">
							<Button className="text-xs mr-2 bg-custom-blue border-custom-blue" type="submit" startIcon={<FiSave size={20} />} loading={isAction} title="Simpan" />
							<Button className="text-xs" color="ghost" type="button" startIcon={<FiXCircle size={20} />} loading={false} title="Kembali" onClick={() => navigate('/group-outlet')} />
						</div>
					</div>

				</form>

			</div>
		</React.Fragment>
	);
};

export default Tambah;
