import React, { useState } from "react";
import moment from "moment";
import { rupiah } from "../../../../utils/strings";
import Logo from "../../../../assets/images/logo/SiResto.png";
import "./style.css"

export const Struk = React.forwardRef(
  (
    {
      data,
      subtotal,
      bayar,
      kembali,
      resto,
      diskon,
      pajak,
      statusPajak,
      statusChargeService,
      chargeService,
      totalSemua,
      tanggal,
      waktu,
      noTransaksi,
      namaPelanggan,
      namaKasir,
      firstPrint
    },
    ref
  ) => {
    moment.locale("id");

    const totalQty = data?.reduce((total, produk) => {
      return total + produk.jumlah_produk;
    }, 0);

    const totalTransaksi = data?.reduce((total, produk) => {
      return total + (produk.jumlah_produk * produk.harga_jual);
    }, 0);

    return (
      <div className="flex flex-col items-center gap-4 justify-center">
        <div ref={ref} id="struk-order">
          {!firstPrint &&
            <p style={{ fontSize: "10px", fontFamily: "Arial, sans-serif" }}>Transaksi Berhasil</p> /* smaller text for 'Salinan Pelanggan' */
          }
          <div style={{ textAlign: "center" }}>
            <img
              src={resto?.logo ? process.env.REACT_APP_API_DOMAIN + resto.logo : Logo}
              alt={`Logo ${data?.resto?.nama_resto || 'Siresto Logo'}`}
              style={{ width: "40mm", margin: "0 auto 2mm" }}
            />
          </div>
          <div className="divider">--------------------------------</div>
          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "8px" }}>
            <div style={{ textAlign: "left" }}>
              <p>{tanggal}</p>
              <p>{waktu}</p>
              <p>{noTransaksi}</p>
            </div>
            <div style={{ textAlign: "right" }}>
              <p>{namaPelanggan}</p>
            </div>
          </div>
          <div className="divider">--------------------------------</div>
          <div style={{ textAlign: "left" }}>
            {data?.map((obj, key) => (
              <div key={key} style={{ marginBottom: "1mm" }}>
                <p>{key + 1}. {obj.nama_produk}</p>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <p>{obj.jumlah_produk} x Rp. {rupiah(obj.harga_jual)}</p>
                  <p className="text-right">Rp. {rupiah(obj.jumlah_produk * obj.harga_jual)}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="divider">--------------------------------</div>
          <div>
            {namaKasir &&
              <div style={{ marginBottom: "2mm" }}>
                <p>Kasir: {namaKasir}</p>
              </div>
            }
            <div style={{ marginBottom: "2mm" }}>
              <p>Total QTY: {totalQty}</p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p className="font-bold">Total:</p>
              <p className="text-right">Rp {rupiah(totalTransaksi)}</p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p>Bayar:</p>
              <p className="text-right">Rp {rupiah(bayar)}</p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p>Kembali:</p>
              <p className="text-right">Rp {rupiah(kembali)}</p>
            </div>
          </div>
          <div className="divider">--------------------------------</div>
          <div style={{ textAlign: "center" }}>
            <p>Terimakasih Telah Berbelanja</p>
          </div>
        </div>
      </div>
    );
  }
);
