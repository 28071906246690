import React, { useState, useEffect } from "react";

// components
import { Button } from "../../../components/Button";
import { InputGroup, Input, MessageError } from "../../../components/Input";
import HeaderContent from "../../../layouts/HeaderContent";

// icons
import { FiSave, FiXCircle } from "react-icons/fi";

// library
import axios from "../../../utils/axios";
import { swNormal } from "../../../utils/sw";
import { useMutation, QueryClient, useQuery } from "react-query";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import AsyncCreatableSelect from "react-select/async-creatable";
import { toastSuccess, toastError } from "../../../utils/toast";

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    height: "3rem",
    paddingLeft: ".5rem",
    paddingRight: ".5rem",
    borderRadius: "10px",
    borderWidth: "1px",
    borderColor: "rgb(59 130 246 / 1)",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      cursor: isDisabled ? "not-allowed" : "default",
    };
  },
};

const EditLisensi = () => {
  const [isAction, setIsAction] = useState(false);
  const [isLoadingSelect, setIsLoadingSelect] = useState(true);
  const user = JSON.parse(localStorage.getItem("user"));
  const queryClient = new QueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState(location.state);
  const [selectedLisence, setSelectedLisence] = useState({
    label: data.users[0]?.lisence?.lisence ?? null,
    value: data.users[0]?.id_lisence ?? null,
  });

  const [isDateValid, setIsDateValid] = useState(false);
  const [expiryDate, setExpiryDate] = useState(new Date(data.created_at));

  useEffect(() => {
    if (data.masa_trial) {
      const expiryDate = new Date(data.created_at);
      expiryDate.setDate(expiryDate.getDate() + data.masa_trial);
      setExpiryDate(expiryDate);
    }
  }, []);

  const formatDate = (date) => {
    const d = new Date(date);
    const month = ('0' + (d.getMonth() + 1)).slice(-2);
    const day = ('0' + d.getDate()).slice(-2);
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const updateMasaTrial = (value) => {
    const selectedDate = new Date(value);
    const timeDiff = selectedDate.getTime() - expiryDate.getTime();
    const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

    if (dayDiff === 0) {
      toastError("Tidak dapat memilih tanggal sebelumnya");
      setIsDateValid(false);
    } else {
      setIsDateValid(true);
      setExpiryDate(selectedDate);
      setData((prevData) => ({
        ...prevData,
        masa_trial: dayDiff,
      }));
    }
  };

  const breadcrumbs = [
    { link: "/", menu: "Home" },
    { link: "/resto", menu: "Resto" },
    { link: "/resto/edit/lisence", menu: "Edit Lisensi Resto" },
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
    control,
    setValue,
    setFocus,
    setError,
  } = useForm();

  useEffect(() => {
    const inputContainer = document.getElementById("content-masa-trial");

    if (selectedLisence.value === 1 || selectedLisence.label === "Premium") {
      inputContainer.classList.add("block");
      inputContainer.classList.remove("hidden");
    } else {
      inputContainer.classList.add("hidden");
      inputContainer.classList.remove("block");
    }
  }, [selectedLisence]);

  const mutation = useMutation(
    async (formData) => {
      formData.lisence = selectedLisence.label;
      formData.id_lisence = selectedLisence.value;
      formData.masa_trial = data.masa_trial;
      if (selectedLisence.value == 1 || selectedLisence.label === "Premium") {
        if (!isDateValid) {
          toastError("Tanggal tidak diubah");
          return false;
        }
      }
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };

      const response = await axios.put("lisence/ubah/" + formData.id, formData, config);
      const res = response.data;

      if (res.meta.code !== 200) {
        throw new Error(res.meta.message);
      }

      return res.data;
    },
    {
      onMutate: () => {
        setIsAction(true);
      },
      onSettled: async (data, error) => {
        setIsAction(false);

        if (data && data !== false) {
          reset();
          clearErrors();
          queryClient.invalidateQueries("data-resto");
        }
      },
      onSuccess: async (data) => {
        if (data !== false) {
          toastSuccess("Resto Berhasil Diubah");
        }
      },
      onError: async () => {
        toastError("Resto Gagal Diubah");
      },
    }
  );

  const promiseOptions = async (inputValue) => {
    setIsLoadingSelect(!isLoadingSelect);
    const response = await axios.get("lisence?s=" + inputValue);
    const res = await response.data.data;

    return res;
  };

  const editLisence = async (data) => {
    await mutation.mutate(data);
  };

  return (
    <>
      <HeaderContent
        linkBack="/resto"
        title="Edit Lisensi Resto"
        breadcrumbs={breadcrumbs}
      ></HeaderContent>
      <div className="bg-white h-max px-6 rounded-lg mt-0 md:mt-4">
        <form onSubmit={handleSubmit(editLisence)}>
          <input
            type="hidden"
            name="id"
            {...register("id")}
            defaultValue={data.id}
          />

          <div className="grid grid-cols-8 gap-1 md:gap-4 mb-4">
            <label className="label col-span-12 md:col-span-2">
              <span className="label-text">Jenis Lisensi</span>
            </label>
            <div className="relative col-span-12 md:col-span-4">
              <Controller
                name="id_lisence"
                control={control}
                render={({ field }) => (
                  <AsyncCreatableSelect
                    styles={colourStyles}
                    cacheOptions
                    defaultOptions
                    loadOptions={promiseOptions}
                    onChange={(selected) => {
                      setSelectedLisence(selected);
                    }}
                    value={selectedLisence}
                  />
                )}
              />
            </div>
          </div>

          <div
            className="grid grid-cols-8 gap-1 md:gap-4 mb-4 hidden duration-200"
            id="content-masa-trial"
          >
            <label className="label col-span-12 md:col-span-2">
              <span className="label-text">
                {selectedLisence.label === "Premium" ? "Tanggal Expired Lisensi" : "Tambah Masa Trial"}
              </span>
            </label>
            <div className="relative col-span-12 md:col-span-4">
              <Input
                type="date"
                placeholder="Tanggal Expired Lisensi"
                name="masa_trial"
                required
                error={errors.masa_trial ? true : false}
                value={formatDate(expiryDate)}
                onChange={(e) => updateMasaTrial(e.target.value)}
              />
              {errors?.masa_trial && (
                <MessageError>Masa Trial Tidak Boleh Kosong</MessageError>
              )}
            </div>
          </div>

          <div className="grid grid-cols-8 md:gap-4 mt-8">
            <div className="relative col-span-12 gap-x-2 md:col-span-4 md:col-start-3">
              <Button
                className="text-xs bg-custom-blue border-custom-blue"
                type="submit"
                startIcon={<FiSave size={20} />}
                loading={isAction}
                title="Simpan"
                disabled={true}
              />
              <Button
                className="text-xs"
                color="ghost"
                type="button"
                startIcon={<FiXCircle size={20} />}
                loading={false}
                title="Kembali"
                onClick={() => navigate("/resto")}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditLisensi;
