import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../../../utils/axios';
import LoadingTable from '../../../components/LoadingTable';
import TableContent from '../../../layouts/TableContent';
import HeaderContent from '../../../layouts/HeaderContent';

const AssignUser = () => {
    const { restoId, id: groupId } = useParams();
    const navigate = useNavigate(); // Initialize navigate
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [hasMultiOutlet, setHasMultiOutlet] = useState(false);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get(`resto/${restoId}/users`);
                setUsers(response.data);
                const groupResponse = await axios.get(`group-outlet/${groupId}`);
                setHasMultiOutlet(!!groupResponse.data.id_multi_outlet);
            } catch (error) {
                console.error("Error fetching users or group outlet info:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchUsers();
    }, [restoId, groupId]);

    const assignMultiOutlet = async (userId) => {
        if (hasMultiOutlet) {
            if (!window.confirm("Anda hanya bisa memilih 1 user menjadi multi outlet. Apakah Anda yakin ingin menetapkan user ini sebagai multi outlet?")) {
                return;
            }
        } else if (!window.confirm("Anda hanya bisa memilih 1 user menjadi multi outlet. Apakah Anda yakin ingin menetapkan user ini sebagai multi outlet?")) {
            return;
        }

        try {
            await axios.post(`group-outlet/${groupId}/assign-multi-outlet`, { user_id: userId });
            alert('User assigned successfully!');
            navigate('/group-outlet'); // Navigate to group-outlet page
        } catch (error) {
            console.error("Error assigning user:", error);
        }
    };

    return (
        <>
            <HeaderContent title="Pilih User untuk Multi Outlet" breadcrumbs={[{ link: '/', menu: 'Home' }, { link: '/group-outlet', menu: 'Group Outlet' }, { link: `/group-outlet/detail/${groupId}/assign/${restoId}`, menu: 'Assign User' }]}>
            </HeaderContent>
            <div className="bg-white mt-4 px-6">
                {isLoading ? (
                    <LoadingTable colSpan="1" />
                ) : users.length > 0 ? (
                    <TableContent>
                        <thead className="text-xs text-blue-500 bg-blue-50 uppercase">
                            <tr>
                                <th scope="col" className="py-3 px-6">Nama User</th>
                                <th scope="col" className="py-3 px-6">Email</th>
                                <th scope="col" className="py-3 px-6">No Telepon</th>
                                <th scope="col" className="py-3 px-6">Aksi</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user) => (
                                <tr className="bg-white border-b" key={user.id}>
                                    <td className="py-4 px-6 text-center">{user.name}</td>
                                    <td className="py-4 px-6 text-center">{user.email}</td>
                                    <td className="py-4 px-6 text-center">{user.no_telepon}</td>
                                    <td className="py-4 px-6 text-center">
                                        <button
                                            className="text-blue-500 underline"
                                            onClick={() => assignMultiOutlet(user.id)}
                                        >
                                            Pilih
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </TableContent>
                ) : (
                    <p className="text-center py-4">Tidak ada user yang tersedia.</p>
                )}
            </div>
        </>
    );
};

export default AssignUser;
