import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../../../utils/axios';
import LoadingTable from '../../../components/LoadingTable';
import TableContent from '../../../layouts/TableContent';
import HeaderContent from '../../../layouts/HeaderContent';
import { FiUserPlus } from 'react-icons/fi';

const DetailOutlet = () => {
    const { id } = useParams();
    const [groupOutlet, setGroupOutlet] = useState(null);
    const [restos, setRestos] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`group-outlet/${id}`);
                const { group_outlet, restos } = response.data;
                setGroupOutlet(group_outlet);
                setRestos(restos);
            } catch (error) {
                console.error("Error fetching outlet details:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [id]);

    return (
        <>
            <HeaderContent title={groupOutlet ? groupOutlet.nama_outlet : "Loading..."} breadcrumbs={[{ link: '/', menu: 'Home' }, { link: '/group-outlet', menu: 'Group Outlet' }, { link: `/group-outlet/detail/${id}`, menu: groupOutlet?.nama_outlet || "Detail" }]}>
            </HeaderContent>
            <div className="bg-white mt-4 px-6">
                <TableContent>
                    <thead className="text-xs text-blue-500 bg-blue-50 uppercase">
                        <tr className="border-b border-blue-200">
                            <th scope="col" className="py-3 px-6 rounded-tl-md">No.</th>
                            <th scope="col" className="py-3 px-6">Nama Resto</th>
                            <th scope="col" className="py-3 px-6">Nama Pemilik</th>
                            <th scope="col" className="py-3 px-6">Kota</th>
                            <th scope="col" className="py-3 px-6">Provinsi</th>
                            <th scope="col" className="py-3 px-6">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading ? (
                            <LoadingTable colSpan="6" />
                        ) : restos && restos.length > 0 ? (
                            restos.map((resto, index) => (
                                <tr className="bg-white border-b" key={resto.id}>
                                    <td className="py-4 px-6 text-center">{index + 1}</td>
                                    <td className="py-4 px-6 text-center">{resto.nama_resto}</td>
                                    <td className="py-4 px-6 text-center">{resto.nama_pemilik}</td>
                                    <td className="py-4 px-6 text-center">{resto.kota}</td>
                                    <td className="py-4 px-6 text-center">{resto.provinsi}</td>
                                    <td className="py-4 px-6 text-center">
                                        <div className="tooltip tooltip-bottom" data-tip={groupOutlet?.id_multi_outlet ? "User Multi Outlet Sudah Di Assign" : "Assign Multi Outlet"}>
                                            {groupOutlet?.id_multi_outlet ? (
                                                <span>User Multi Outlet Sudah Di Assign</span>
                                            ) : (
                                                <button
                                                    onClick={() => navigate(`/group-outlet/detail/${id}/assign/${resto.id}`)}
                                                    className={`border rounded p-2 hover:bg-blue-100`}
                                                >
                                                    <FiUserPlus size={18} />
                                                </button>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td className="py-4 px-6 text-center font-medium w-max" colSpan="6">
                                    Data Resto Kosong
                                </td>
                            </tr>
                        )}
                    </tbody>
                </TableContent>
            </div>
        </>
    );
};

export default DetailOutlet;